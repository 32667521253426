import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api, UserDataType } from "../../api/api";
import {logout} from "../../redux/slice/auth";

export type StatusType = 'idle' | 'pending' | 'succeeded' | 'failed';

export type StatusesType = {
	[ key: string ]: StatusType;
};

interface InitialStateType
{
	data: UserDataType;
	status: StatusesType;
}

export const getUserData =
	createAsyncThunk(
		'userData/getUserData',
		async ( token: string, { dispatch } ) =>
			{
				try
				{
					const { data } = await api.getUserData( token );
					return data;
				}
				catch( error: any )
				{
					if( error?.response?.status === 401 || error?.response?.status === 403 )
					{
						dispatch( logout() );
					}
				}
			} );

export const updateUserPhone =
	createAsyncThunk(
		'userData/updateUserPhone',
		async (
			{
				phoneNumber,
				token
			}: { token: string | null, phoneNumber: string; },
			{ dispatch }
		) =>
		{
			try
			{
				await api.updateUserPhone( { phone: phoneNumber, JWT: token } );
				const { data } = await api.getUserData( token );
				return data;
			}
			catch( error: any )
			{
				if( error?.response?.status === 401 || error?.response?.status === 403 )
				{
					dispatch( logout() );
				}
			}
		} );

export const buyPremium =
	createAsyncThunk(
		'userData/buyPremium',
		async ( token: string | null, { dispatch } ) =>
		{
			try
			{
				await api.buyPremium( token );
				const { data } = await api.getUserData( token );
				return data;
			}
			catch( error: any )
			{
				if( error?.response?.status === 401 || error?.response?.status === 403 )
				{
					dispatch( logout() );
				}
			}
		} );

const initialState: InitialStateType = {
	data: {} as UserDataType,
	status: {
		getUserDataStatus: 'idle',
		updateUserPhoneStatus: 'idle',
		buyPremiumStatus: 'idle'
	}
};

const userDataSlice = createSlice( {
	name: 'userData',
	initialState,
	reducers:
	{
		resetStatus: ( state, action: PayloadAction<'getUserDataStatus' | 'updateUserPhoneStatus' | 'buyPremiumStatus'> ) =>
		{
			state.status[ action.payload ] = 'idle';
		}
	},
	extraReducers: ( builder ) =>
	{
		builder.addCase( getUserData.pending, ( state: InitialStateType ) =>
		{
			state.status.getUserDataStatus = 'pending';
			state.data = {} as UserDataType;
		} );
		builder.addCase( getUserData.fulfilled, ( state: InitialStateType, action ) =>
		{
			state.status.getUserDataStatus = 'succeeded';
			state.data = action.payload || {} as UserDataType;
		} );
		builder.addCase( getUserData.rejected, ( ( state: InitialStateType ) =>
		{
			state.status.getUserDataStatus = 'failed';
			state.data = {} as UserDataType;
		} ) );
		builder.addCase( updateUserPhone.pending, ( ( state: InitialStateType ) =>
		{
			state.status.updateUserPhoneStatus = 'pending';
		} ) );
		builder.addCase( updateUserPhone.fulfilled, ( state: InitialStateType, action ) =>
		{
			state.status.updateUserPhoneStatus = 'succeeded';
			state.data = action.payload || {} as UserDataType;
		} );
		builder.addCase( updateUserPhone.rejected, ( ( state: InitialStateType ) =>
		{
			state.status.updateUserPhoneStatus = 'failed';
		} ) );
		builder.addCase( buyPremium.pending, ( ( state: InitialStateType ) =>
		{
			state.status.buyPremiumStatus = 'pending';
		} ) );
		builder.addCase( buyPremium.fulfilled, ( state: InitialStateType, action ) =>
		{
			state.status.buyPremiumStatus = 'succeeded';
			state.data = action.payload || {} as UserDataType;
		} );
		builder.addCase( buyPremium.rejected, ( ( state: InitialStateType ) =>
		{
			state.status.buyPremiumStatus = 'failed';
		} ) );
	}
} );

export const userDataReducer = userDataSlice.reducer;
export const { resetStatus } = userDataSlice.actions


