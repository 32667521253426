import React, { useEffect, ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import MainPage from "./pages/MainPage/MainPage";
import { setToken } from "./redux/slice/auth";
import { AppRootStateType, useAppDispatch } from "./redux/store";


function App()
{
    const dispatch = useAppDispatch();
    const token = useSelector<AppRootStateType, string | null>( state => state.auth.SecurityKey );

    useEffect( () =>
    {
        if( process.env.NODE_ENV === "production" )
            dispatch( setToken( window.localStorage.getItem( "token" ) ) );
    }, [] );


    if( !token )
    {
        return <LoginPage />;
    }

    return (
        <div id="outer-container">
            <Routes>
                { token && <>
                    <Route path={ "/" } element={ <MainPage /> } />
                    <Route path={ "*" } element={ <Navigate to="/" /> } />
                    <Route path={ "/login" } element={ <LoginPage /> } />
                </> }
            </Routes>
        </div>
    );
}

export default App;
