import React from 'react';
import Modal from "../Modal/Modal";
import styles from './DefaultModal.module.scss'


type PropsType = {
    img: string
    title: string
    description: string
    isOpen: boolean
    buttonText: string
    setIsOpen: () => void
    onClickButton: () => void
}

const DefaultModal = ({img, title, description, isOpen, setIsOpen, buttonText, onClickButton}: PropsType) => {
    return (
        <Modal isOpenModal={isOpen} setOpenModal={setIsOpen}>
            <div className={styles.modalContainer}>
                <img src={img} className={styles.icon} alt='message image'/>
                <span className={styles.title}>{title}</span>
                <span className={styles.description}>{description}</span>
                <button className={styles.button} onClick={onClickButton}>{buttonText}</button>
            </div>
        </Modal>
    );
};

export default DefaultModal;
