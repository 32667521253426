import {combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import {authReducer} from "./slice/auth";
import {userDataReducer} from "./slice/userData";

const rootReducer = combineReducers({
    auth: authReducer,
    userData: userDataReducer
})

const store = configureStore({
    reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export type AppRootStateType = ReturnType<typeof rootReducer>

export default store
