import React, {useState} from 'react';
import styles from './GetMinutes.module.scss'
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../redux/store";

const GetMinutes = () => {
    const [checkedProduct, setCheckedProduct] = useState('')
    const token = useSelector<AppRootStateType, string | null>(state => state.auth.SecurityKey)

    return (
        <div className={styles.getMinutesBlock}>
            <div className={styles.title}>Get Minutes</div>
            <form action='/stripe_checkout' className={styles.productsForm} method="POST">
                <div className={`${checkedProduct === '22' && styles.active} ${styles.productCard}`}>
                    <input onChange={e => setCheckedProduct(e.target.value)} id='22' type="radio"
                           name="productId" value='22'/>
                    <label htmlFor='22'>
                        <div className={styles.productInfo}>
                            <span className={styles.productTitle}>500 minutes</span>
                        </div>
                    </label>
                    <div className={styles.productsPrice}>
                        <span className={styles.newPrice}>$8.00</span>
                    </div>
                </div>
                <div className={`${checkedProduct === '20' && styles.active} ${styles.productCard}`}>
                    <input onChange={e => setCheckedProduct(e.target.value)} id='20' type="radio" name="productId"
                           value='20'/>
                    <label htmlFor='20'>
                        <div className={styles.productInfo}>
                            <span className={styles.productTitle}>300 minutes</span>
                        </div>
                    </label>
                    <div className={styles.productsPrice}>
                        <span className={styles.newPrice}>$5.00</span>
                    </div>
                </div>
                <div className={`${checkedProduct === '19' && styles.active} ${styles.productCard}`}>
                    <input onChange={e => setCheckedProduct(e.target.value)} id='19' type="radio" name="productId"
                           value='19'/>
                    <label htmlFor='19'>
                        <div className={styles.productInfo}>
                            <span className={styles.productTitle}>100 minutes</span>
                        </div>
                    </label>
                    <div className={styles.productsPrice}>
                        <span className={styles.newPrice}>$2.00</span>
                    </div>
                </div>
                <div className={`${checkedProduct === '18' && styles.active} ${styles.productCard}`}>
                    <input onChange={e => setCheckedProduct(e.target.value)} id='18' type="radio" name="productId"
                           value='18'/>
                    <label htmlFor='18'>
                        <div className={styles.productInfo}>
                            <span className={styles.productTitle}>Caller number</span>
                        </div>
                    </label>
                    <div className={styles.productsPrice}>
                        <span className={styles.newPrice}>$5.00</span>
                    </div>
                </div>
                <input type='hidden' name='jwt'
                       value={token ? token : ''}
                       id="jwt"/>
                <div className={styles.paymentButton}>
                    <button disabled={!checkedProduct} type={'submit'} className={styles.defaultBtn}>
                        Pay with credit card
                    </button>
                </div>

            </form>
        </div>
    );
};

export default GetMinutes;
