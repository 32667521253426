import React, {useState} from 'react';
import styles from './SidebarMenu.module.scss'
import Logout from "../Logout/Logout";
import MenuItem from "../MenuItem/MenuItem";
import {watchingModes} from "../../../pages/MainPage/MainPage";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../redux/store";
import {UserDataType} from "../../../api/api";

type PropsType = {
    setWatchingMode: (value: watchingModes) => void
    watchingMode: watchingModes
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

const SidebarMenu = ({watchingMode, setWatchingMode, isOpen, setIsOpen}: PropsType) => {
    const menuItems: Array<{ title: string, key: watchingModes }> = [
        {title: 'Caller number', key: 'callerNumber'},
        {title: 'Get Minutes', key: 'getMinutes'},
        {title: 'IndyCall App', key: 'app'},
    ]

    const data = useSelector<AppRootStateType, UserDataType>((state: AppRootStateType) => state.userData.data)


    return (
        <div onClick={() => setIsOpen(false)} className={isOpen ? `${styles.menu} ${styles.menuActive}` : styles.menu}>
            <div className={styles.overlay}/>
            <div className={styles.menuContent} onClick={e => e.stopPropagation()}>
                <div className={styles.main}>
                    <nav className={styles.menuItems}>
                        {menuItems.map(value => <span key={value.key}  className={styles.menuItem}>
                            <MenuItem id={value.key} onClick={(value) => {
                                setWatchingMode(value)
                                setIsOpen(false)

                            }}
                                      watchingMode={watchingMode} title={value.title}/>
                        </span>)}
                    </nav>

                </div>
                <div className={styles.footer}>
                    <div className={styles.menuInfo}>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoTitle}>Indycall ID:</span>
                            <span className={styles.infoDescription}>{data?.userArea?.uniqCode ? data?.userArea?.uniqCode : ''}</span>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.infoTitle}>Google ID:</span>
                            <span className={styles.infoDescription}>{data?.userArea?.email ? data?.userArea?.email : ''}</span>
                        </div>
                    </div>
                    <Logout/>
                </div>
            </div>
        </div>
    );
};

export default SidebarMenu;
