import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import styles from "./MainPage.module.scss";
import UserInfo from "../../components/UserInfo/UserInfo";
import Banner from "../../components/Banner/Banner";
import {UserDataType} from "../../api/api";
import {AppRootStateType, useAppDispatch} from "../../redux/store";
import {getUserData, StatusesType} from "../../redux/slice/userData";
import {useSelector} from "react-redux";
import Loader from "../../components/Loader/Loader";
import CallerNumberInfo from "../../components/CallerNumberInfo/CallerNumberInfo";
import GetMinutes from "../../components/GetMinutes/GetMinutes";
import {useLocation, useNavigate} from "react-router-dom";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import successIcon from './../../assets/acceptIcon.svg'
import errorIcon from './../../assets/rejectIcon.svg'

export type watchingModes = 'callerNumber' | 'getMinutes' | 'app'

const MainPage = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {search} = useLocation();

    const query = new URLSearchParams(search);
    const isSuccess = query.get('success') ? JSON.parse(query.get('success') || '') : null

    const [isOpen, setIsOpen] = useState(!!query.get('success'))

    const [watchingMode, setWatchingMode] = useState<watchingModes>('callerNumber')

    const data = useSelector<AppRootStateType, UserDataType>((state: AppRootStateType) => state.userData.data)
    const {getUserDataStatus} = useSelector<AppRootStateType, StatusesType>(state => state.userData.status)
    const token = useSelector<AppRootStateType, string | null>(state => state.auth.SecurityKey)

    useEffect(() => {
        if (token) {
            dispatch(getUserData(token))
        }
    }, [])


    const purchasedMinutes = data?.purchasedMinutes ? Math.floor(data?.purchasedMinutes / 60) : 0;

    return (
        <div>
            <Header watchingMode={watchingMode} setWatchingMode={setWatchingMode}/>
            <main className={styles.mainContent} id="page-wrap">
                {getUserDataStatus === 'pending' ? <Loader/> : null}
                {isSuccess && <DefaultModal
                    onClickButton={() => {
                        setIsOpen(false)
                        navigate('/')
                    }}
                    buttonText='OK' img={successIcon} title={'Payment successful!'}
                    description={'The purchase is added to your account'} isOpen={isOpen}
                    setIsOpen={() => setIsOpen(false)}/>
                }
                {!isSuccess && <DefaultModal
                    onClickButton={() => {
                        setIsOpen(false)
                        navigate('/')
                    }}
                    buttonText='Cancel' img={errorIcon} title={'Payment error'}
                    description={'Payment was unsuccessful'} isOpen={isOpen}
                    setIsOpen={() => setIsOpen(false)}/>
                }
                <UserInfo credit={purchasedMinutes}
                          indycallId={data?.userArea?.uniqCode}
                          googleId={data?.userArea?.email}
                          setWatchingMode={setWatchingMode}
                />
                <div className={styles.mainContentWrapper}>
                    {
                        watchingMode === 'callerNumber' &&
                        <CallerNumberInfo numberChanges={data?.premiumNumber?.changeCount}
                                          phoneNumber={data?.premiumNumber?.number}
                                          callerNumberStatus={data?.premiumNumber?.dateValid}
                                          expired={data?.premiumNumber?.expired}
                                          credit={purchasedMinutes}
                        />
                    }
                    {
                        watchingMode === 'getMinutes' && <GetMinutes/>
                    }
                    {
                        watchingMode === 'app' && <Banner/>
                    }
                </div>
            </main>
        </div>
    );
};

export default MainPage;
