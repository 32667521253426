import React from 'react';
import styles from "./Logo.module.scss";
import logo from "../../../assets/logoIcon.svg";

type PropsType = {
    marginForText: string
}

const Logo = ({marginForText}: PropsType) => {
    return (
        <div className={styles.logoContainer}>
            <img className={styles.logo} src={logo} alt={'logo'}/>
            <span className={styles.logoText} style={{ marginLeft: marginForText }}>IndyCall <span>web panel</span></span>
        </div>
    );
};

export default Logo;
