import React from 'react';
import styles from './Banner.module.scss'
import qrCodeImg from './../../assets/qrCode.png'

const Banner = () => {
    return (
        <div className={styles.bannerContainer}>
            <span className={styles.bannerTitle}>Download IndyCall</span>
            <span className={styles.bannerDescription}>The latest app version can be downloaded and installed manually from this link.</span>
            <img className={styles.bannerImage} src={qrCodeImg} alt='download app'/>
            <a href={'https://app.indyweb.site/indycall_download'} className={styles.bannerDownloadBtn}>
                Download app
            </a>
        </div>
    );
};

export default Banner;
