import React from 'react';
import styles from './Loader.module.scss'

const Loader = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.loaderContent}>
                <span className={styles.dots}/>
            </div>
        </div>

    );
};

export default Loader;
