import React from 'react';
import styles from './UserInfo.module.scss'
import {watchingModes} from "../../pages/MainPage/MainPage";

type PropsType = {
    credit: number
    indycallId: number
    googleId: string
    setWatchingMode: (value: watchingModes) => void
}

const UserInfo = ({credit, indycallId, googleId, setWatchingMode}: PropsType) => {
    return (
        <div className={styles.userInfoContainer}>
            <div className={styles.creditInfoBlock}>
                <span className={styles.creditTitle}>Credit <span>{credit} indyminutes</span></span>
                <button className={styles.textBtn} onClick={() => setWatchingMode('getMinutes')}>Buy more</button>
            </div>
            <div className={styles.userIdsBlock}>
                <span className={styles.userIdTitle}>Indycall ID: <span>{indycallId}</span></span>
                <span
                    className={`${styles.userIdTitle} ${styles.userIdMargin}`}>Google ID: <span>{googleId}</span></span>
            </div>
        </div>
    );
};

export default UserInfo;
