import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppRootStateType} from "../store";

interface InitialStateType {
    SecurityKey: string | null
}

const initialState = {
    SecurityKey: process.env.NODE_ENV === "production" ? null : process.env.REACT_APP_DEBUG_TOKEN
} as InitialStateType

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string | null>) => {
            state.SecurityKey = action.payload
        },
        logout: state => {
            state.SecurityKey = null
        }
    },
})

export const selectIsAuth = (state: AppRootStateType) => Boolean(state.auth.SecurityKey)

export const authReducer = authSlice.reducer

export const {logout, setToken} = authSlice.actions
