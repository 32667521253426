import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from './CallerNumberInfo.module.scss'
import moment from "moment";
import acceptIcon from './../../assets/acceptIcon.svg'
import logoIcon from './../../assets/logoIcon.svg'
import exchangeFailedIcon from './../../assets/exchangeFailedIcon.svg'
import exchangeIcon from './../../assets/exchangeIcon.svg'
import {useSelector} from "react-redux";
import {AppRootStateType, useAppDispatch} from "../../redux/store";
import {buyPremium, resetStatus, StatusesType, updateUserPhone} from "../../redux/slice/userData";
import Modal from "../Modal/Modal";
import DefaultModal from "../DefaultModal/DefaultModal";
import changeNumberPhoneFailedIcon from './../../assets/changeNumberPhoneFailedIcon.svg'
import successIcon from "../../assets/acceptIcon.svg";

type PropsType = {
    phoneNumber: number
    callerNumberStatus: string
    numberChanges: number
    expired: 0 | 1
    credit: number
}

const CallerNumberInfo = ({callerNumberStatus, numberChanges, expired, phoneNumber, credit}: PropsType) => {
    const dispatch = useAppDispatch()

    const token = useSelector<AppRootStateType, string | null>(state => state.auth.SecurityKey)
    const {
        buyPremiumStatus,
        updateUserPhoneStatus
    } = useSelector<AppRootStateType, StatusesType>(state => state.userData.status)

    const [newPhone, setNewPhone] = useState('+')
    const [showInput, setShowInput] = useState(false)
    const [isOpenUpdatePhoneModal, setIsOpenUpdatePhoneModal] = useState(false)
    const [isOpenConversationModal, setIsOpenConversationModal] = useState(false)
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
    const [showModal, setShowModal] = useState(updateUserPhoneStatus === 'failed' || buyPremiumStatus === 'failed' || buyPremiumStatus === 'succeeded')

    const isActivePhone = numberChanges > 0 && !expired

    useEffect(() => {
        setShowModal(updateUserPhoneStatus === 'failed' || buyPremiumStatus === 'failed' || buyPremiumStatus === 'succeeded')
    }, [updateUserPhoneStatus, buyPremiumStatus])

    const onChangePhoneInput = (e: ChangeEvent<HTMLInputElement>) => {
        const regExp = new RegExp(/^[+\d](?:.*\d)?$/gm) // '+' and numbers
        const numberRepetition = new RegExp(/(\d)\1{4}/gm)
        const value = e.target.value

        if (value === '') {
            return setNewPhone('+')
        }

        if (regExp.test(value)) {
            setNewPhone(value)
        }
        if (value.length > 10
            && (!value.startsWith('+0')
                && !value.startsWith('+800')
                && !value.startsWith('+910')
                && !value.startsWith('+911')
                && !value.startsWith('+912')
                && !value.startsWith('+913')
                && !value.startsWith('+914')
                && !value.startsWith('+915'))
            && !numberRepetition.test(value)) {
            setIsSaveButtonDisabled(false)
        } else {
            setIsSaveButtonDisabled(true)
        }

    }

    const onSaveButtonClick = () => {
        dispatch(updateUserPhone({phoneNumber: newPhone, token}))
        setNewPhone('+')
        setShowInput(false)
        setIsOpenUpdatePhoneModal(false)
    }

    const handleCloseErrorModal = (key: 'getUserDataStatus' | 'updateUserPhoneStatus' | 'buyPremiumStatus') => {
        setShowModal(false)
        dispatch(resetStatus(key))
    }

    return (
        <div className={styles.infoContainer}>
            <div className={styles.infoBlock}>
                <span className={styles.infoTitle}>Caller number</span>
                <div className={`${styles.infoRow} ${styles.telephoneBlock}`}>
                    <span className={styles.infoRowTitle}>
                        {showInput ? 'Set your Caller number. Short, fake and Indian landline numbers can’t be used' : 'Your Caller Number:'}
                    </span>
                    {
                        ( phoneNumber || showInput ) &&
                        <div className={styles.telephoneRow}>
                            {
                                showInput ?
                                    <div className={styles.inputContainer}>
                                        <input className={styles.input} value={newPhone} onChange={onChangePhoneInput}/>
                                        <button
                                            onClick={() => setIsOpenUpdatePhoneModal(true)}
                                            disabled={isSaveButtonDisabled}
                                            className={`${styles.saveButton}`}>
                                            Save
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowInput(false)
                                                setNewPhone('+')
                                            }}
                                            className={`${styles.cancelButton}`}>
                                            Cancel
                                        </button>
                                    </div> :
                                    <>
                                        <span className={`${styles.telephone} ${!numberChanges ? styles.disabled : ''}`}>+{phoneNumber}</span>
                                        <button onClick={() => setShowInput(true)} disabled={!isActivePhone}
                                                className={styles.telephoneBtn}>Change
                                        </button>
                                    </>
                            }
                        </div>
                    }
                    {
                        ( !phoneNumber && !showInput ) &&
                        <>
                            {
                                numberChanges > 0 ?
                                <>
                                    <span className={`${styles.notPhone} ${!numberChanges ? styles.disabled : ''}`}>Not set</span>
                                    <button onClick={() => setShowInput(true)} disabled={!isActivePhone} className={styles.telephoneBtn}>
                                        Change
                                    </button>
                                </> :
                                <span className={styles.notPhone}>Buy caller number pack to set your number</span>
                            }
                        </>

                    }
                    {
                        isOpenUpdatePhoneModal && <Modal isOpenModal={isOpenUpdatePhoneModal} setOpenModal={() => {
                            setIsOpenUpdatePhoneModal(false)
                            setNewPhone('+')
                        }}>
                            <div className={styles.modalContainer}>
                                <span>Change your number to</span>
                                <span>{newPhone}</span>
                                <div className={styles.buttonsBlock}>
                                    <button className={styles.outlinedButton} onClick={() => {
                                        setNewPhone('+')
                                        setIsOpenUpdatePhoneModal(false)
                                    }}>Cancel
                                    </button>
                                    <button className={styles.fulfilledButton} onClick={() => onSaveButtonClick()}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    }
                    {
                        isOpenConversationModal && <Modal isOpenModal={isOpenConversationModal}
                                                          setOpenModal={() => setIsOpenConversationModal(false)}>
                            <div className={styles.modalContainer}>
                                <img src={exchangeIcon} alt='exchange minutes'/>
                                Exchage 400 minutes credit for caller number pack?
                                <div className={styles.buttonsBlock}>
                                    <button className={styles.outlinedButton}
                                            onClick={() => setIsOpenConversationModal(false)}>
                                        Cancel
                                    </button>
                                    <button className={styles.fulfilledButton}
                                            onClick={() => {
                                                dispatch(buyPremium(token))
                                                setIsOpenConversationModal(false)
                                            }}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    }
                    {updateUserPhoneStatus === 'failed' &&
                        <DefaultModal img={changeNumberPhoneFailedIcon} title='Oops... Number is not set'
                                      description='Please try again later' isOpen={showModal} buttonText='OK'
                                      setIsOpen={() => handleCloseErrorModal('updateUserPhoneStatus')}
                                      onClickButton={() => handleCloseErrorModal('updateUserPhoneStatus')}/>}
                    {buyPremiumStatus === 'failed' &&
                        <DefaultModal img={exchangeFailedIcon} title='Oops... Conversion failed'
                                      description='Please try again later' isOpen={showModal} buttonText='OK'
                                      setIsOpen={() => handleCloseErrorModal('buyPremiumStatus')}
                                      onClickButton={() => handleCloseErrorModal('buyPremiumStatus')}/>
                    }
                    {buyPremiumStatus === 'succeeded' &&
                        <DefaultModal img={successIcon} title='Payment successful!'
                                      description='The purchase is added to your account' isOpen={showModal}
                                      buttonText='OK'
                                      setIsOpen={() => handleCloseErrorModal('buyPremiumStatus')}
                                      onClickButton={() => handleCloseErrorModal('buyPremiumStatus')}/>
                    }
                </div>
                {
                    (phoneNumber || numberChanges !== 0) && <>
                        <div className={styles.infoRow}>
                            <span className={styles.infoRowTitle}>Caller Number Status:</span>
                            <span
                                className={styles.infoRowDescription}>{!expired ? `active till ${moment(callerNumberStatus).format('MMMM DD, YYYY')}` : `not active. Expired on ${moment(callerNumberStatus).format('MMMM DD, YYYY')}`}
                        </span>
                        </div>
                        <div className={styles.infoRow}>
                            <span className={styles.infoRowTitle}>Number Changes Left:</span>
                            <span className={styles.infoRowDescription}>{numberChanges ? numberChanges : 0}</span>
                        </div>
                    </>
                }

            </div>
            <div className={styles.card}>
                <span className={styles.cardTitle}>Caller number pack</span>
                <div className={styles.cardInfo}>
                    <img src={acceptIcon} className={styles.cardImage} alt={'check icon'}/>
                    <span>Valid for <b>3</b> months</span>
                </div>
                <div className={styles.cardInfo}>
                    <img src={acceptIcon} className={styles.cardImage} alt={'check icon'}/>
                    <span><b>5</b> number changes</span>
                </div>
                <div className={styles.cash}>$5.00</div>
                <form action='/stripe_checkout' method="POST">
                    <input id='18' type="hidden" name="productId"
                           value='18'/>
                    <input type='hidden' name='jwt'
                           value={token ? token : ''}
                           id="jwt"/>
                    <button type={'submit'} className={styles.defaultBtn}>
                        Pay with credit card
                    </button>
                </form>
                <button
                    disabled={credit < 400}
                    onClick={() => setIsOpenConversationModal(true)}
                    className={styles.payBtn}>
                    <img src={logoIcon} alt="logo"/> <span>Pay with 400 Indyminutes</span>
                </button>

            </div>
        </div>
    );
};

export default CallerNumberInfo;
