import React from 'react';
import styles from './Logout.module.scss'
import {useAppDispatch} from "../../../redux/store";
import {googleLogout} from "@react-oauth/google";
import {logout} from "../../../redux/slice/auth";

const logoutIcon = <svg className={styles.logoutIcon} width="23" height="23" viewBox="0 0 23 23"
                        xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4_7)">
        <path
            d="M11.4618 21.0514H2.86543C2.33818 21.0514 1.9103 20.6235 1.9103 20.0963V2.90356C1.9103 2.37631 2.33823 1.94843 2.86543 1.94843H11.4618C11.99 1.94843 12.4169 1.5215 12.4169 0.993305C12.4169 0.465113 11.99 0.0380859 11.4618 0.0380859H2.86543C1.28562 0.0380859 0 1.32375 0 2.90356V20.0962C0 21.6761 1.28562 22.9617 2.86543 22.9617H11.4618C11.99 22.9617 12.4169 22.5347 12.4169 22.0065C12.4169 21.4784 11.99 21.0514 11.4618 21.0514Z"/>
        <path
            d="M22.7154 10.8199L16.908 5.08895C16.5336 4.71834 15.9281 4.72315 15.5575 5.09851C15.1869 5.47388 15.1907 6.07849 15.567 6.44909L19.7171 10.5448H8.59633C8.06814 10.5448 7.6412 10.9717 7.6412 11.4999C7.6412 12.0281 8.06814 12.4551 8.59633 12.4551H19.7171L15.567 16.5507C15.1907 16.9213 15.1879 17.5259 15.5575 17.9013C15.7447 18.0904 15.9911 18.1859 16.2375 18.1859C16.4802 18.1859 16.7227 18.0942 16.908 17.9108L22.7154 12.1799C22.8969 12.0004 23 11.7558 23 11.4998C23 11.244 22.8978 11.0004 22.7154 10.8199Z"/>
    </g>
    <defs>
        <clipPath id="clip0_4_7">
            <rect width="23" height="23"/>
        </clipPath>
    </defs>
</svg>

const Logout = () => {
    const dispatch = useAppDispatch()

    const doLogout = () => {
        googleLogout()
        dispatch(logout())
        localStorage.clear();
        window.location.reload();
    }
    return (
        <div className={styles.logoutContainer} onClick={() => doLogout()}>
            <span className={styles.logoutText}>Sign Out</span>
            {logoutIcon}
        </div>
    );
};

export default Logout;
