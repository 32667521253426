import {useGoogleLogin} from '@react-oauth/google';
import React, {useEffect, useState} from 'react';
import styles from './LoginPage.module.scss'
import googleIcon from './../../assets/googleIcon.png'
import {useAppDispatch} from "../../redux/store";
import {setToken} from "../../redux/slice/auth";
import {api} from "../../api/api";
import Logo from '../../components/Header/Logo/Logo';
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import autFailedIcon from './../../assets/autFailedIcon.svg'
import {StatusType} from "../../redux/slice/userData";


const LoginPage = () => {
    const dispatch = useAppDispatch()

    const [status, setStatus] = useState<StatusType>('idle')
    const [isOpen, setIsOpen] = useState(status === 'failed')

    useEffect(() => {
        setIsOpen(status === 'failed')
    }, [status])

    const login = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (tokenResponse) => {
            try {
                const {data} = await api.login(tokenResponse.code)
                if (data.SecurityKey) {
                    dispatch(setToken(data.SecurityKey))
                    window.localStorage.setItem('token', data.SecurityKey);
                }
                setStatus('succeeded')
            } catch (e) {
                console.log(e)
                setStatus('failed')
            }
        }
    });

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBlock}>
                {status === 'failed' &&
                    <DefaultModal onClickButton={() => setIsOpen(false)} setIsOpen={() => setIsOpen(false)} isOpen={isOpen}
                                  title='Authentication failed' description='Please try again later' img={autFailedIcon}
                                  buttonText={'OK'}/>}
                <Logo marginForText={'15px'}/>
                <h1 className={styles.loginTitle}>Welcome to Indycall</h1>
                <h3 className={styles.loginSubtitle}>Sign In to continue to your IndyCall account</h3>

                <button className={styles.loginButton} onClick={() => login()}>
                    <img src={googleIcon} className={styles.loginButtonIcon} alt='Login with Google'/>
                    Sign in with Google
                </button>
            </div>
        </div>
    );
};

export default LoginPage;
