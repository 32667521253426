import axios from "axios";

const instance = axios.create({
    // baseURL: process.env.REACT_APP_API_PATH,
})

//API
export const api = {
    getUserData(token: string |null) {
        const fetchConfig = {headers: {Authorization: `Bearer ${token}`}}

        return instance.post<UserDataType>(`/api/getUserData`, {} , fetchConfig)
    },
    updateUserPhone({JWT, phone}: { JWT: string | null, phone: string }) {
        const fetchConfig = {headers: {Authorization: `Bearer ${JWT}`}}
        return instance.post(`/api/doChangePremNumber`, {PhoneNumber: phone}, fetchConfig)
    },
    buyPremium(token: string | null) {
        const fetchConfig = {headers: {Authorization: `Bearer ${token}`}}
        return instance.post(`/api/buyPremium`, {}, fetchConfig)
    },
    login(token: string | null) {
        return instance.post<AuthType>(`/api/doRegister`, {authCode: token})
    }
}

//types
export type UserDataType = {
    purchasedMinutes: number
    premiumNumber: {
        changeCount: number
        dateValid: string
        expired: 0 | 1
        number: number
        tabNumberEnabled: number
    }
    userArea: {
        uniqCode: number
        email: string
    }
    userTariff: {
        wait_time: number
    }
}

export type AuthType = {
    SecurityKey: string
}
