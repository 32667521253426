import React, {useState} from 'react';
import styles from './Header.module.scss';
import Logo from "./Logo/Logo";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import menuIcon from './../../assets/burgerMenuIcon.svg'
import closeMenuIcon from './../../assets/closeMenuIcon.svg'
import Logout from "./Logout/Logout";
import MenuItem from "./MenuItem/MenuItem";
import {watchingModes} from "../../pages/MainPage/MainPage";

type PropsType = {
    watchingMode: watchingModes
    setWatchingMode: (value: watchingModes) => void
}

const Header = ({setWatchingMode, watchingMode}: PropsType) => {
    const [isOpen, setIsOpen] = useState(false)

    const menuItems: Array<{ title: string, key: watchingModes }> = [
        {title: 'Caller number', key: 'callerNumber'},
        {title: 'Get Minutes', key: 'getMinutes'},
        {title: 'IndyCall App', key: 'app'},
    ]

    return (
        <header className={styles.headerBlock}>
            <div className={styles.wrapper}>
                <Logo marginForText={'10px'}/>
                <nav className={styles.headerNav}>
                    {menuItems.map((value, index) => <span  key={value.key} style={index > 0 && index < menuItems.length - 1 ? {
                        marginLeft: '48px',
                        marginRight: '48px'
                    } : {}}>
                            <MenuItem dashed id={value.key} onClick={setWatchingMode}
                                      watchingMode={watchingMode} title={value.title}/>
                        </span>)
                    }
                </nav>
                <div className={styles.logoutContainer}>
                    <Logout/>
                </div>
                <nav className={styles.mobileNav} onClick={() => setIsOpen(!isOpen)}>
                    <img src={isOpen ? closeMenuIcon : menuIcon} alt="menu"/>
                </nav>
                <SidebarMenu isOpen={isOpen} setIsOpen={setIsOpen} watchingMode={watchingMode}
                             setWatchingMode={setWatchingMode}/>
            </div>
        </header>
    );
};

export default Header;
