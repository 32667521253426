import React from 'react';
import styles from './MenuItem.module.scss'
import {watchingModes} from "../../../pages/MainPage/MainPage";

type PropsType = {
    onClick: (value: watchingModes) => void
    watchingMode: watchingModes
    title: string
    dashed?: boolean
    id: watchingModes
}

const MenuItem = ({watchingMode, onClick, title, dashed = false, id}: PropsType) => {
    const style = (watchingMode === id && dashed) ? {textDecoration: 'underline', textUnderlineOffset: '42px', textDecorationThickness: '2px'} : {}

    return (
        <span style={style} onClick={() => onClick(id)}
              className={`${watchingMode === id ? styles.headerNavItemActive : styles.headerNavItem}`}>{title}</span>
    );
};

export default MenuItem;
